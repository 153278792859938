import store from "@/store";

// 检测token是否存在
export const checkIsToken = () => {
  const token = store.state.token;
  if (token === null || token === "") {
    return false;
  } else {
    return true;
  }
};

// 刷新当前页面
export const refreshCurrentPage = (that: any, item: string) => {
  console.log(that.$route.name, item);
  that.$route.name === item
    ? that.$router.go(0)
    : that.$router.push("/" + item.toLowerCase());
};
