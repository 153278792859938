import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";

Vue.use(ElementUI);

Vue.config.productionTip = false;

// 阻止Promise.reject控制台输出
window.addEventListener(
  "unhandledrejection",
  function browserRejectionHandler(event) {
    event && event.preventDefault();
  }
);

const app = new Vue({
  router,
  store,
  render: (h) => h(App),
  beforeCreate() {
    Vue.prototype.$bus = this;
  },
});
// app.use(router);
app.$mount("#app");
