import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    navHeight: "80px",
    navCommonHeight: "271px",
    footerHeight: "40px",
    token: window.sessionStorage.getItem("token"),
    // 总允许申请岗位
    allowPosition: 5,
    // 剩余岗位
    surplusPosition: 3,
    phone: "",
    phoneType: "",
  },
  getters: {},
  mutations: {
    setToken(state, payload) {
      state.token = payload.token;
    },
    setPhone(state, payload) {
      state.phone = payload.phone;
    },
    setPhoneType(state, payload) {
      state.phoneType = payload.phoneType;
    },
  },
  actions: {
    setToken(context, payload) {
      context.commit("setToken", payload);
    },
    setPhone(context, payload) {
      context.commit("setPhone", payload);
    },
    setPhoneType(context, payload) {
      context.commit("setPhoneType", payload);
    },
  },
  modules: {},
});
