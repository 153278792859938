import Vue from "vue";
import VueRouter, { RawLocation, Route, RouteConfig } from "vue-router";
import { checkIsToken } from "@/utils/token-and-router";

import { Message } from "element-ui";
import "element-ui/lib/theme-chalk/index.css";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    redirect: "/home",
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/login/index.vue"),
  },
  {
    path: "/home",
    name: "Home",
    component: () => import("@/views/home/index.vue"),
  },
  {
    path: "/job",
    name: "Job",
    component: () => import("@/views/job/index.vue"),
  },
  {
    path: "/mine",
    name: "Mine",
    component: () => import("@/views/mine/index.vue"),
  },
  {
    path: "/resume",
    name: "Resume",
    component: () => import("@/views/resume/index.vue"),
  },
  // {
  //   path: "/privacy-policy",
  //   name: "PrivacyPolicy",
  //   component: () => import("@/components/agreement/privacy-policy.vue"),
  // },
  // {
  //   path: "/user-agreement",
  //   name: "UserAgreement",
  //   component: () => import("@/components/agreement/user-agreement.vue"),
  // },
  {
    path: "/check-apply",
    name: "CheckApply",
    component: () => import("@/views/apply/index.vue"),
  },
  {
    path: "/prevent",
    name: "Prevent",
    component: () => import("@/views/prevent/index.vue"),
  },
  {
    path: "*",
    redirect: "/home",
  },
];

const routerPush = VueRouter.prototype.push;
const routerReplace = VueRouter.prototype.replace;
// push
VueRouter.prototype.push = function push(location: RawLocation) {
  return (routerPush.call(this, location) as any).catch((err: Error) => err);
};
// replace
VueRouter.prototype.replace = function push(location: RawLocation) {
  return (routerReplace.call(this, location) as any).catch((err: Error) => err);
};

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

const whiteList = ["/login", "/", "/home", "/job"]; // no redirect whitelist
const hasLogWhiteList = ["/", "/home", "/mine", "/job"]; // 已登录可以前往的页面

router.beforeEach(function (to: Route, from: Route, next) {
  // console.log(from, "from");
  const userDevicer = [
    "iPhone",
    "iPad",
    "Android",
    "SymbianOS",
    "iPod",
    "Windows Phone",
  ].some((agentType) => navigator.userAgent.indexOf(agentType) !== -1);
  console.log(userDevicer, to, "to");
  if (to.path !== "/prevent" && userDevicer) {
    console.log("888888888");
    Message({
      center: true,
      message: "即将跳转至移动端页面",
      type: "info",
    });
    // window.sessionStorage.clear();
    // router.replace("/prevent");
    next("/prevent");
    // next();
    setTimeout(() => {
      window.open("https://m-zhaopin.greeyun.com");
    }, 500);
  } else if (to.path === "/prevent" && !userDevicer) {
    next({
      path: "/",
    });
  } else if (checkIsToken() && !userDevicer) {
    if (to.path === "/login") {
      // if is logged in, redirect to the home page
      Message({
        message: "您已登录格力招聘系统，请先退出当前帐号！",
        type: "error",
      });
      next({ path: "/" });
    } else if (hasLogWhiteList.indexOf(to.path) !== -1) {
      console.log("我可以随意出入");
      next();
    } else {
      console.log("我不能随意出入");
      console.log(hasLogWhiteList.indexOf(to.path));
      // console.log(from, "from");
      // console.log(to, "to");
      // console.log(to.fullPath);
      // console.log(to.fullPath.includes("JobCode"));
      if (to.fullPath.includes("JobCode")) {
        next();
      } else {
        Message({
          message: "请从规定渠道进入此页面，即将跳转至首页！",
          type: "error",
        });
        next({
          path: "/",
        });
      }
    }
  } else if (!userDevicer) {
    console.log(whiteList.indexOf(to.path));

    if (whiteList.indexOf(to.path) !== -1) {
      // in the free Login whitelist, go directly
      next();
    } else {
      console.log("我没有登录");

      // other pages that do not have permission to access are redirected to the Login page.
      next({
        path: "/login",
        query: {
          redirect: to.fullPath,
        },
      });
    }
  } else {
    console.log(from, "from", to, "to", "last");
    next();
  }
});

export default router;
